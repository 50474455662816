import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { floor, subtract } from 'lodash';
import DownloadGreen from 'assets/icons/download_green.svg';
import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import BookImage from './BookImage';

interface OrderSummaryProps {
  title: string;
  upsellsKeys: string[];
  bundlePhoto: { [key: string]: number };
}

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 1rem;
  gap: 4rem;
  @media ${tablet} {
    gap: 3rem;
    padding: 3rem 1rem;
  }
`;

const Heading = styled(Text)`
  color: #1c1c28;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.04rem;
  text-align: center;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 130%;
    letter-spacing: -0.024rem;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 872px;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 1.5rem;
  width: 100%;
  padding-bottom: 2rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
    gap: 0.5rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  justify-content: end;
  gap: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.light100};

  padding-top: 2rem;
  @media ${tablet} {
    justify-content: space-between;
    padding-top: 0.5rem;
  }
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  @media ${tablet} {
    gap: 5px;
  }
`;

const UpsellDownload = styled.a`
  cursor: pointer;
  color: #00a676 !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 140%;
  text-decoration: underline !important;
  scroll-behavior: smooth;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 18px;
`;

const StyledImg = styled(DynamicImage)`
  width: 50px;
  @media ${tablet} {
    width: 33px;
  }
`;

const ItalicText = styled(Text)`
  font-style: italic;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
`;

const Subtitle = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4375rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
  }
`;

const ScrollToAppWrapper = styled.div`
  color: #00a676;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 140%;
  text-decoration-line: underline;
  scroll-behavior: smooth;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
  }
`;

const ProductTitle = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: normal;
  }
`;

const BabyName = styled.p<{ bookColor: string }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-family: 'Brandon Grotesque', sans-serif;
  font-size: 0.3rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: 0.9rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  white-space: nowrap;
  @media ${tablet} {
    font-size: 0.2rem;
    top: 0.55rem;
  }
  @media ${mobile} {
    font-weight: 400;
  }
`;

const BottomNames = styled.p<{ bookColor: string; isLongText: boolean }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-size: 0.14rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  bottom: ${({ isLongText }) => (isLongText ? '0.25rem' : '0.5rem')};
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  font-family: 'Brandon Grotesque', sans-serif;
  z-index: 3;
  text-align: center;
  @media ${tablet} {
    font-size: 0.075rem;
    line-height: 1px;
    bottom: ${({ isLongText }) => (isLongText ? '0.27rem' : '0.3rem')};
  }
  @media ${mobile} {
    font-weight: 400;
  }
`;

const EbookContainer = styled.div`
  display: flex;
  padding: 0.05rem 0.31794rem;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  bottom: -3px;
  border-radius: 0.27181rem;
  background: rgb(243, 101, 35);
  z-index: 3;
  right: 50%;
  transform: translateX(50%);
  color: rgb(250, 241, 235);
  text-align: center;
  font-size: 0.2rem;
  font-weight: 800;
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${tablet} {
    max-width: 2.1875rem;
  }
`;

const UpsellTitle = styled.p`
  color: #555770;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01238rem;
  padding-left: 5.06rem;
  @media ${tablet} {
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 0;
    padding-left: 2.6875rem;
  }
`;

const MultipleUpsellLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Total = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8125rem;
  @media ${tablet} {
    font-size: 1.125rem;
    line-height: 140%;
  }
`;

const Price = styled.p`
  color: #000;
  text-align: right;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Free = styled.p`
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: #00a676;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 100%;
  @media ${tablet} {
    font-size: 0.6875rem;
  }
`;
const Prices = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

const OldPrice = styled.div`
  color: #717171;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: line-through;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const AppDownload = styled(UpsellDownload)<{ isReadyToDownload: boolean }>`
  cursor: ${({ isReadyToDownload }) =>
    isReadyToDownload ? 'pointer' : 'default'};
  opacity: ${({ isReadyToDownload }) => (isReadyToDownload ? '1' : '0.6')};
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  upsellsKeys,
  bundlePhoto,
}) => {
  const { isTablet } = useQuery();
  const products = useSelector(
    (state: AppState) => state.funnel.products,
  ) as Product[];

  const {
    selected_plans,
    upsell_products,
    selected_plans_options,
    quiz_answers,
  } = useSelector((state: AppState) => state.user);

  const selectedProduct = getLocalisedProduct(selected_plans[0]);
  const currency = selectedProduct?.currencyId;
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const boughtUpsell = upsell_products.map(item => item.key);

  const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const getBundlePhoto = () => {
    let result = '';
    sortedUpsells?.forEach(str => {
      if (bundlePhoto[str] !== undefined) {
        result += bundlePhoto[str].toString();
      }
    });
    if (result === '') {
      return '1234';
    }
    return result;
  };

  const renderNames = () => {
    const { mom_name, dad_name, baby_name, parenting_situation } =
      quiz_answers || {};

    let namesString = '';

    switch (parenting_situation) {
      case 'mom':
        namesString = `${mom_name} & ${baby_name}`;
        break;
      case 'dad':
        namesString = `${dad_name} & ${baby_name}`;
        break;
      default:
        namesString = `${mom_name}, ${dad_name} & ${baby_name}`;
        break;
    }

    const isLongText = namesString.length > 35;

    if (
      (isLongText && parenting_situation === 'parents') ||
      (isLongText && parenting_situation.includes('two'))
    ) {
      namesString = namesString.replace(' & ', ' &<br/> ');
    }

    return { namesString, isLongText };
  };

  const { namesString, isLongText } = renderNames();

  const upsellsPrice = localisedUpsellProducts?.reduce(
    (sum, p) => sum + parseFloat(p?.finalPrice || '0'),
    0,
  );

  const totalPrice = selected_plans.reduce((prevValue, product) => {
    const productPrice = product.prices.filter(
      priceItem => priceItem.currency_id == currency,
    )[0]?.final_price;
    return prevValue + productPrice;
  }, 0);

  const totalPriceUpsell = upsell_products.reduce((prevValue, product) => {
    const productPrice = product.prices.filter(
      priceItem => priceItem.currency_id == currency,
    )[0]?.final_price;
    return prevValue + productPrice;
  }, 0);

  const resolvePageTextContent = product => {
    let summaryProduct;

    if (product.key.includes('discounted')) {
      const productKey = product.key.replace(/-discounted(?=-|$)/, '');
      const newProduct = products.find(item => item.key === productKey);
      summaryProduct = newProduct ? newProduct : product;
    } else if (product.key.includes('free')) {
      const productKey = product.key.replace(/-free$/, '');
      const newProduct = products.find(item => item.key === productKey);
      summaryProduct = newProduct ? newProduct : product;
    } else {
      summaryProduct = product;
    }

    const priceToShow = product?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.final_price;

    const oldPrice = product?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.price;

    const priceToShowDiscounted = summaryProduct?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.final_price;

    const oldPriceDiscounted = summaryProduct?.prices?.filter(
      priceItem => priceItem.currency_id === currency,
    )[0]?.price;
    const isEbook =
      product.type === 'digital' &&
      product.is_subscription === false &&
      product.is_upsell === false;
    const scrollHeight = isTablet ? 350 : 600;
    if (
      product?.is_subscription &&
      product?.is_upsell &&
      product?.includes('upsell-welcome-baby-1-month')
    ) {
      return {
        title: product?.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() =>
              window.scrollTo({ top: scrollHeight, behavior: 'smooth' })
            }
          >
            <DownloadGreen />
            Download here
          </ScrollToAppWrapper>
        ),
        priceToShow,
        productImg: 'success/subscriptionProductImg.png',
      };
    }

    if (product?.is_subscription && product.is_upsell) {
      return {
        title: product.name,
        priceToShow,
        hasImage: false,
        is_upsell: product?.is_upsell,
      };
    }
    if (
      product?.is_subscription &&
      !selected_plans_options.paperBook &&
      !selected_plans_options.ebookCheck
    ) {
      return {
        title: product.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() =>
              window.scrollTo({ top: scrollHeight, behavior: 'smooth' })
            }
          >
            <DownloadGreen />
            {product?.description}
          </ScrollToAppWrapper>
        ),
        priceToShow,
        oldPrice,
        productImg: 'success/subscriptionProductImg.png',
        isEbook: false,
      };
    }
    if (
      product?.is_subscription &&
      (selected_plans_options.paperBook || selected_plans_options.ebookCheck)
    ) {
      return {
        title: product.name,
        subtitle: (
          <ScrollToAppWrapper
            onClick={() =>
              window.scrollTo({ top: scrollHeight, behavior: 'smooth' })
            }
          >
            <DownloadGreen />
            {product?.description}
          </ScrollToAppWrapper>
        ),
        priceToShow: 'FREE',
        oldPrice,
        productImg: 'success/subscriptionProductImg.png',
      };
    }

    if (product.type === 'physical' && !selected_plans_options.subContainer) {
      return {
        title: product.name,
        subtitle: product.description,
        priceToShow,
        oldPrice,
        productImg: `booksCovers/${quiz_answers.color}_${quiz_answers.icon}.png`,
      };
    }
    if (product.type === 'physical' && selected_plans_options.subContainer) {
      return {
        title: summaryProduct.name,
        subtitle: summaryProduct.description,
        priceToShowDiscounted,
        oldPriceDiscounted,
        productImg: `booksCovers/${quiz_answers.color}_${quiz_answers.icon}.png`,
      };
    }

    if (product.key.includes('welcome-baby-book-bundle')) {
      const planKeys = upsell_products.map(up => up.key);
      return {
        title: product.name,
        subtitle: (
          <MultipleUpsellLinkContainer>
            {planKeys.includes('upsell-sleep-guide') ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fthe_essential_baby_sleep_guide.pdf?alt=media&token=cb742df5-8292-4ad7-baf2-56adddfc5349'
                }
                target="_blank"
              >
                <DownloadGreen />
                The Essential Baby Sleep Guide
              </UpsellDownload>
            )}
            {planKeys.includes(
              'upsell-raising-emotionally-intelligent',
            ) ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fraising_an_emotionally_intelligent_child.pdf?alt=media&token=51c0bd0e-52ed-4f56-b02e-04e84ed4d06c'
                }
                target="_blank"
              >
                <DownloadGreen />
                Raising An Emotionally Intelligent Child
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-development-workbook') ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fkids_developement_workbook.pdf?alt=media&token=932d6ff4-5f5b-4a63-b186-85d90da76a23'
                }
                target="_blank"
              >
                <DownloadGreen />
                Kids’ Development Workbook
              </UpsellDownload>
            )}
            {planKeys.includes('upsell-baby-care-planners') ? null : (
              <UpsellDownload
                href={
                  'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fultimate_baby_care_planners.pdf?alt=media&token=ee9a1d59-5e26-4ad9-96ea-a659d3921993'
                }
                target="_blank"
              >
                <DownloadGreen />
                Ultimate Baby Care Planners
              </UpsellDownload>
            )}
          </MultipleUpsellLinkContainer>
        ),
        priceToShow,
        productImg: `upsell/bundle/bundle-${getBundlePhoto()}.png`,
      };
    }
    if (product.key.includes('upsell-sleep-guide')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fthe_essential_baby_sleep_guide.pdf?alt=media&token=cb742df5-8292-4ad7-baf2-56adddfc5349'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/sleep-guide/card.png',
      };
    }
    if (product.key.includes('upsell-raising-emotionally-intelligent')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fraising_an_emotionally_intelligent_child.pdf?alt=media&token=51c0bd0e-52ed-4f56-b02e-04e84ed4d06c'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/emotionally-intelligent/card.png',
      };
    }
    if (product.key.includes('upsell-development-workbook')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fkids_developement_workbook.pdf?alt=media&token=932d6ff4-5f5b-4a63-b186-85d90da76a23'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/development-workbook/card.png',
      };
    }
    if (product.key.includes('upsell-baby-care-planners')) {
      return {
        title: product.name,
        subtitle: (
          <UpsellDownload
            href={
              'https://firebasestorage.googleapis.com/v0/b/welcome-baby-app-prod.appspot.com/o/upsells%2Fultimate_baby_care_planners.pdf?alt=media&token=ee9a1d59-5e26-4ad9-96ea-a659d3921993'
            }
            target="_blank"
          >
            <DownloadGreen />
            Download here
          </UpsellDownload>
        ),
        priceToShow,
        productImg: 'upsell/care-planner/card.png',
      };
    }
    if (
      (product.key.includes('e-book-free') && product.is_upsell) ||
      (product.key.includes('upsell-e-book-express-shipping') &&
        product.is_upsell) ||
      (product.key.includes('hardcover-book-upsell') && product.is_upsell)
    ) {
      return {
        title: product.name,
        priceToShow,
        is_upsell: product?.is_upsell,
        subtitle: product?.description,
      };
    }
    if (
      isEbook &&
      !selected_plans_options.paperBook &&
      !selected_plans_options.subContainer
    ) {
      return {
        title: product.name,
        priceToShow,
        oldPrice,
        productImg: `booksCovers/${quiz_answers.color}_${quiz_answers.icon}.png`,
        isEbook: true,
        subtitle: quiz_answers?.ebookUrl ? (
          <AppDownload
            href={quiz_answers?.ebookUrl}
            target="_blank"
            isReadyToDownload
          >
            <DownloadGreen />
            Download here
          </AppDownload>
        ) : (
          <AppDownload isReadyToDownload={false}>
            <DownloadGreen />
            Download here
          </AppDownload>
        ),
      };
    }
    if (isEbook && selected_plans_options.paperBook) {
      return {
        title: summaryProduct.name,
        priceToShowDiscounted: 'FREE',
        oldPriceDiscounted,
        productImg: `booksCovers/${quiz_answers.color}_${quiz_answers.icon}.png`,
        isEbook: true,
        subtitle: quiz_answers?.ebookUrl ? (
          <AppDownload
            href={quiz_answers?.ebookUrl}
            target="_blank"
            isReadyToDownload
          >
            <DownloadGreen />
            Download here
          </AppDownload>
        ) : (
          <AppDownload isReadyToDownload={false}>
            <DownloadGreen />
            Download here
          </AppDownload>
        ),
      };
    }
    if (isEbook && selected_plans_options.subContainer) {
      return {
        title: summaryProduct.name,
        priceToShowDiscounted,
        oldPriceDiscounted,
        productImg: `booksCovers/${quiz_answers.color}_${quiz_answers.icon}.png`,
        isEbook: true,
        subtitle: quiz_answers?.ebookUrl ? (
          <AppDownload
            href={quiz_answers?.ebookUrl}
            target="_blank"
            isReadyToDownload
          >
            <DownloadGreen />
            Download here
          </AppDownload>
        ) : (
          <AppDownload isReadyToDownload={false}>
            <DownloadGreen />
            Download here
          </AppDownload>
        ),
      };
    }
  };

  const subscriptionsSummary = [];
  const paperBookSummary = [];
  const eBookSummary = [];
  const otherBundles = [];

  const allProducts = [...(selected_plans || []), ...(upsell_products || [])];

  allProducts?.forEach(product => {
    if (product.is_subscription) {
      subscriptionsSummary.push({ ...product, hasImage: true });
      return;
    }
    if (
      product.type === 'physical' ||
      product.key.includes('hardcover-book-upsell')
    ) {
      paperBookSummary.push({ ...product, hasImage: true });
      return;
    }
    if (
      product.key.includes('e-book-free') ||
      product.key.includes('upsell-e-book-express-shipping') ||
      product.key.includes('e-book-downsell') ||
      product.key.includes('e-book')
    ) {
      eBookSummary.push({ ...product, hasImage: true, isEbook: true });
      return;
    } else {
      otherBundles.push({ ...product, hasImage: true, isEbook: true });
      return;
    }
  });

  const groupedProductsArray = [
    ...(subscriptionsSummary || []),
    ...(paperBookSummary || []),
    ...(eBookSummary || []),
    ...(otherBundles || []),
  ];

  const hasBoughtLifeTime = groupedProductsArray.find(item =>
    item.key.includes('welcome-baby-lifetime'),
  );

  return (
    <Container>
      <Heading>{title}</Heading>
      <SummaryContainer>
        {groupedProductsArray?.map(item => {
          const {
            title,
            subtitle,
            priceToShow,
            priceToShowDiscounted,
            productImg,
            isEbook,
            oldPrice,
            oldPriceDiscounted,
          } = resolvePageTextContent(item);

          const hideOldPrice =
            item.is_upsell === true ||
            (!selected_plans_options.paperBook &&
              !selected_plans_options.ebookCheck &&
              selected_plans_options.subContainer) ||
            (!selected_plans_options.paperBook &&
              selected_plans_options.ebookCheck &&
              !selected_plans_options.subContainer) ||
            (!selected_plans_options.paperBook &&
              selected_plans_options.ebookCheck &&
              selected_plans_options.subContainer);

          const isPaperback = item.type === 'physical';

          return (
            <OrderItemContainer key={title}>
              {productImg && (
                <BookContainer>
                  <DynamicImage
                    width={isTablet ? '2.125rem' : '3.5rem'}
                    src={productImg}
                    alt="product"
                  />
                  {(isEbook || isPaperback) && (
                    <>
                      <BabyName bookColor={quiz_answers?.color}>
                        {quiz_answers?.baby_name}
                      </BabyName>
                      <BottomNames
                        bookColor={quiz_answers?.color}
                        dangerouslySetInnerHTML={{ __html: namesString }}
                        isLongText={isLongText}
                      />
                      {isEbook && <EbookContainer>ebook</EbookContainer>}
                    </>
                  )}
                </BookContainer>
              )}
              {productImg ? (
                <ProductInfoContainer>
                  <PriceContainer>
                    <ProductTitle>{title}</ProductTitle>
                    {item.key.includes('welcome-baby-1-month') &&
                    hasBoughtLifeTime ? null : (
                      <Prices>
                        {!hideOldPrice && (
                          <OldPrice>
                            {selectedProduct?.currency +
                              (oldPrice
                                ? oldPrice
                                : oldPriceDiscounted
                              ).toFixed(2)}
                          </OldPrice>
                        )}
                        {(priceToShow || priceToShowDiscounted) === 'FREE' ? (
                          <Free>FREE</Free>
                        ) : (
                          <Price>
                            {selectedProduct?.currency +
                              (priceToShow
                                ? priceToShow
                                : priceToShowDiscounted
                              ).toFixed(2)}
                          </Price>
                        )}
                      </Prices>
                    )}
                  </PriceContainer>

                  <Subtitle>{subtitle}</Subtitle>
                </ProductInfoContainer>
              ) : (
                <ProductInfoContainer>
                  <PriceContainer>
                    <UpsellTitle>{title}</UpsellTitle>
                    <Price>
                      {selectedProduct?.currency + priceToShow.toFixed(2)}
                    </Price>
                  </PriceContainer>

                  <Subtitle>{subtitle}</Subtitle>
                </ProductInfoContainer>
              )}
            </OrderItemContainer>
          );
        })}
        <TotalItemContainer>
          <Total>Total:</Total>
          <Total>
            {selectedProduct?.currency}
            {(
              Math.round(Number(totalPrice + upsellsPrice) * 100) / 100
            ).toFixed(2)}
          </Total>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;
